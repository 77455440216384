@import url(./assets/fonts/Akkurat.css);
@import url(./assets/fonts/AkkuratMono.css);

html {padding: 0;}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
    min-height: 100vh;
    background-color: #2c2f38;
    font-family: Akkurat;
    font-size: 20px;
    line-height: 1.5em;
}

#root > header {
    background-color: white;
}

.header__ident {
    display: flex;
    align-items: center;
    padding: 22px 16px;
}

.logo {
    width: 58.33333px;
    height: 30px;
    color: currentColor;
}

article {
    margin: 0 2em;
}

@media screen and (min-width: 720px) and (min-height: 480px), screen and (min-width: 1024px) {
    .logo {
        width: 62.22222px;
        height: 32px;
    }

    article {
        max-width: 626px;
        margin: 0 auto;
    }
}

@media (min-width: 1280px) {
    .logo {
        width: 70px;
        height: 36px;
    }

    article {
        max-width: 704px;
    }
}

.header__title {
    font-weight: 700;
    font-size: 1em;
    padding: 0 0.5em;
    margin: 0;
}

.logo svg {
    fill: currentColor;
}

main {
    height: 100%;
    padding: 0 1em 1em;
    background-color: #f2f2f2;
}

nav {
    display: flex;
    justify-content: center;
}

nav button {
    padding: 1em;
    color: #2c2f38;
    font-size: .8em;
    cursor: pointer;
    text-decoration: underline;
    border: 0;
}

nav button[aria-disabled=true] {
    text-decoration: none;
    opacity: .5;
    cursor: default;
}

h2 {
    font-size: 1.25em;
}

article button {
    margin-top: 1em;
    padding: 0.75em 1.5em;
    border: 2px solid;
    border-radius: 2px;
    line-height: 1;
    font-weight: 700;
}

article button:not([disabled]) {
    cursor: pointer;
    border-color: black;
}

article button:hover:not([disabled]) {
    color: white;
    background-color: black;
}

label {
    display: block;
    font-weight: 700;
}

label.help--error {
    font-weight: normal;
    color: red;
}

textarea {
    display: block;
    margin-top: 1em;
    width: 100%;
    height: 7em;
    font-size: 0.6em;
    box-sizing: border-box;
}

footer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 16px;
    color: #fff;
}

footer h3 {
    font-size: 14px;
    width: 100%;
    text-align: center;
}

.section--step::before {
    position: absolute;
    left: 0;
    width: 100%;
    content: '';
    border-top: 1px solid #d9d9d9;
}

.section--step[aria-expanded=false] {
    cursor: pointer;
}

.section--step[aria-expanded=false]:hover {
    text-decoration: underline;
}

.section--step h3 {
    padding-top: 1.5em;
    font-size: 1em;
}

.section--step[aria-expanded=true] [data-step]::before {
    background-color: #0666b0;
    color: #fff;
}

.section--step [data-step]::before {
    content: attr(data-step) "";
    box-sizing: border-box;
    width: 1.5em;
    height: 1.5em;
    border-radius: 100%;
    text-align: center;
    position: absolute;
    margin: -.1em 0 0 -2em;
    padding-top: .1em;
    background-color: #d9d9d9;
}

.section--step[aria-expanded=false] .content {
    display: none;
}

.section--step fieldset {
    padding: 0;
    margin: 0;
    border: 0;
    font-size: .8em;
}

.section--step fieldset > input, .section--step fieldset select {
    width: 100%;
    padding: 8px 10px;
    color: #222;
    outline: none;
    box-sizing: border-box;
}

.section--step fieldset input {
    border: 1px solid #ccc;
}

.section--step fieldset .radiogroup label {
    display: initial;
    margin: .4rem;
}

.section--step fieldset .radiogroup.language {
    margin-bottom: 1em;
}

.section--step fieldset select
{
    height: auto;
    padding: 8px 30px 8px 10px;
    text-overflow: ellipsis;
}

.section--step fieldset input:hover, .section--step fieldset input:focus {
    border-color: #0666b0;
}

.wizard__cols {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 1em;
}

.wizard__cols div {
     width: 33%;
     box-sizing: border-box;
}

.wizard__cols div:not(:first-child) {
    padding-left: .5em;
}

select:not(first-of-type) + label, input:not(first-of-type) + label {
    margin-top: 1em;
}
