@charset "UTF-8";
/*
 * Legal Disclaimer
 *
 * These Fonts are licensed only for use on these domains and their subdomains:
 * kulturit.no
 *
 * It is illegal to download or use them on other websites.
 *
 * While the @font-face statements below may be modified by the client, this
 * disclaimer may not be removed.
 *
 * Lineto.com, 2015
 */
/*
 *
 * INSTRUCTIONS
 *
 * Copy the Legal Disclaimer and the @font-faces statements to your regular CSS file.
 * The fonts folder(s) should be placed relative to the regular CSS file.
 *
 * You can use either the complete or subsetted fonts:
 * If you don’t require the entire range of characters, you can use the smaller, subsetted webfonts instead.
 * See "Glyphs & Languages" for an overview of the characters in the *_subsetted.html file in the root directory of this package.
 *
 */
/* Complete */
@font-face {
  font-family: "Akkurat";
  font-weight: normal;
  font-style: normal;
  src: url("./Akkurat/fonts/lineto-akkurat-pro-regular.eot");
  src: url("./Akkurat/fonts/lineto-akkurat-pro-regular.eot?#iefix") format("embedded-opentype"), url("./Akkurat/fonts/lineto-akkurat-pro-regular.woff") format("woff"); }

@font-face {
  font-family: "Akkurat";
  font-weight: bold;
  font-style: normal;
  src: url("./Akkurat/fonts/lineto-akkurat-pro-bold.eot");
  src: url("./Akkurat/fonts/lineto-akkurat-pro-bold.eot?#iefix") format("embedded-opentype"), url("./Akkurat/fonts/lineto-akkurat-pro-bold.woff") format("woff"); }

@font-face {
  font-family: "Akkurat";
  font-weight: normal;
  font-style: italic;
  src: url("./Akkurat/fonts/lineto-akkurat-pro-Italic.eot");
  src: url("./Akkurat/fonts/lineto-akkurat-pro-Italic.eot?#iefix") format("embedded-opentype"), url("./Akkurat/fonts/lineto-akkurat-pro-Italic.woff") format("woff"); }

@font-face {
  font-family: "Akkurat";
  font-weight: bold;
  font-style: italic;
  src: url("./Akkurat/fonts/lineto-akkurat-pro-boldItalic.eot");
  src: url("./Akkurat/fonts/lineto-akkurat-pro-boldItalic.eot?#iefix") format("embedded-opentype"), url("./Akkurat/fonts/lineto-akkurat-pro-boldItalic.woff") format("woff"); }
